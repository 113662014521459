body {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  background-color: #0d0d0d;
  color: #ffffff;
  font-family: "K2D", sans-serif;
}
.dFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.royalrightImg {
  width: auto !important;
  max-width: 97px;
  margin-top: 34px;
  margin-right: -3px;
}
.numberText1 {
  left: -70px !important;
}
.numberText {
  position: absolute;
  left: -46px;
  font-size: 101px !important;
  color: #ca772a;
  top: 54px;
  z-index: 9;
}
.layoubackgroundImg {
  position: fixed;
  width: auto;
  max-width: 100%;
  opacity: 0.5;
  // z-index: 1;
}
.sideMenuBox {
  height: calc(100vh - 450px);
  overflow: auto;
}
.sideMenuBox::-webkit-scrollbar {
  display: none;
}
.logoDrawerBox {
  padding-top: 22px;
  /* top: 20px; */
  width: auto;
  cursor: pointer;
  max-width: 119px;
}
.paperBox {
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 15px;
  padding: 20px;
}

.slickbottomslider {
  width: 100%;
  position: relative;
  .slick-initialized .slick-slide.slick-current {
    .active {
      transform: scale(1.15);
      transition: all 0.4s ease-in;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }

  .slick-track {
    padding: 60px 0;
  }

  .slick-prev:before {
    content: "‹";
  }

  .slick-next:before {
    content: "›";
  }
  .slick-list {
    padding-left: 0px !important; // half partial
  }
  .slick-prev {
    left: auto !important;
    // bottom: -20px !important;
    top: -14px !important;
    right: 60px !important;
    color: #fff !important;
    z-index: 999 !important;
    background-color: #1c1c1b !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    @media (max-width: 767px) {
      top: 21px !important;
    }
  }

  .slick-next {
    top: -14px !important;
    // bottom: -20px !important;
    left: auto !important;
    right: 5px !important;
    color: #fff !important;
    background-color: #161616 !important;
    z-index: 999 !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    @media (max-width: 767px) {
      top: 21px !important;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 0.75;
    color: #f5c843;
    margin-top: -17px;
  }
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 18px !important;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgba(255, 212, 129, 1) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid rgba(255, 212, 129, 1);
  border-radius: 50px;
  width: 21px !important;
  height: 21px !important;
  line-height: 23px !important;
}
.slick-dots {
  bottom: -27px !important;
  z-index: 999;
  @media (max-width: 1556px) {
    bottom: -38px !important;
  }
}
.slickbottomslider {
  .slick-track {
    padding: 40px 0 23px;
  }
}
.yellowShade {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 30%;
  top: 50%;
  transform: translateX(-50%);
  background: radial-gradient(
    29.3% 29.3% at 55.72% 49.12%,
    #ffca64 0%,
    rgba(245, 200, 67, 0) 100%
  );
  opacity: 0.45;
  filter: blur(190px);
  max-width: 100%;
}
.tableShadow {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 50%;
  top: -13%;
  transform: translateX(-50%);
  background: radial-gradient(
    29.3% 29.3% at 55.72% 49.12%,
    #ffca64 0%,
    rgba(245, 200, 67, 0) 100%
  );
  opacity: 0.45;
  filter: blur(301px);
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    92.79deg,
    #ffb000 -32.2%,
    #ffb000 5.51%,
    #ca772a 54.01%,
    #ca772a 110.93%
  );
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    92.79deg,
    #ffb000 -32.2%,
    #ffb000 5.51%,
    #ca772a 54.01%,
    #ca772a 110.93%
  );
}
::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}
::-webkit-scrollbar-corner {
  background: #f2f2f2;
}

////////h2h//////

body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // font-family: 'Saira', sans-serif;
  // background: #000;
}
.royalText {
  font-size: 35px !important;
  font-weight: 600;
  font-family: "K2D", sans-serif !important;
  line-height: 45px !important;
  text-align: left;
  text-transform: uppercase;
}
.displayWrap {
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // flex-wrap: wrap;
}
.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectGridBox {
  width: 20%;
}
.logoImg {
  width: 120px;
}
.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bgx1 {
  -webkit-animation: rotating 26s linear infinite;
  animation: rotating 26s linear infinite;
}

.bgx2 {
  -webkit-animation: rotating2 6s linear infinite;
  animation: rotating2 6s linear infinite;
}

.crypto {
  -webkit-animation: rotating3 3s linear infinite;
  animation: rotating3 3s linear infinite;
}

@-webkit-keyframes rotating3 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.goldshadow {
  width: 800px;
  top: 25%;
  opacity: 0.3;
  filter: blur(130px);
  height: 400px;
  border-radius: 1000px;
  left: 23%;
  z-index: -1;
  position: absolute;
  background-image: radial-gradient(
    22.67% 9.68% at 67.26% 8.27%,
    #ffca64 86%,
    #ffca64 41%
  );
  @media (max-width: 991px) {
    width: 216px;
    height: 216px;
  }
  @media (max-width: 401px) {
    width: 106px;
    height: 106px;
  }
}
@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
.sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
  img {
    width: auto;
    max-width: 165px;
  }
}

.shadeBox {
  right: auto;
  left: 0;
  width: 450px;
  /* bottom: auto; */
  filter: blur(230px);
  height: 550px;
  opacity: 0.55;
  top: 0;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    #ff6600 0%,
    #ff66004f 95.78%
  );
}

.orangeshadeBox {
  right: 0;
  left: 34%;
  width: 400px;
  /* bottom: auto; */
  filter: blur(147px);
  height: 400px;
  opacity: 0.55;
  top: 42%;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgba(255, 202, 100, 0.6705882353) 0%,
    rgba(226, 150, 62, 0.81) 95.78%
  );
}

.TopbarButton {
  padding: 8px 24px;
  margin-right: 10px !important;
}
.centerImg {
  position: absolute !important;
  left: 0 !important;
  /* background: gray; */
  width: 38px;
  margin-right: 41px;
}
.centerbannerimg {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  z-index: 1;
  opacity: 0.5;
  min-width: 800px;
  max-width: 60%;
}
.orangeshadeBoxWon {
  right: 0;
  left: 34%;
  width: 500px;
  /* bottom: auto; */
  filter: blur(227px);
  height: 500px;
  opacity: 0.55;
  top: 21%;
  z-index: 1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgb(255 202 100 / 57%) 0%,
    rgb(226 150 62 / 68%) 95.78%
  );
}

.dollerImg1 {
  position: absolute;
  right: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg2 {
  position: absolute;
  left: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}
.dollerImg3 {
  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 119px !important;
  bottom: -29px;
}
.ticketbannerImg {
  position: absolute;
  top: -122px;
  z-index: -1;
  width: auto !important;
  max-width: 100% !important;
}

.ticketAnimationBox {
  animation: 3s ease-in-out 0s infinite normal none running rotating4;
}

@keyframes rotating4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  backdrop-filter: blur(4px);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.animationText {
  color: #fff;
  animation: 2s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0.5;
    transform: translateY(80%);
  }
  20% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.3;
    transform: translateY(0%);
  }
  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.hedaerTopbar {
  padding: 20px 0;
}
.containerBox {
  padding: 0px 80px !important;
}
.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #5e4a2e;
  border-radius: 50px;
  width: fit-content;
  padding: 3px;
}

.tabActiveButtons {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);

  border-radius: 50px;
  padding: 11px 35px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
  font-family: "Readex Pro";
  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgba(184, 184, 184, 1);
  font-family: "Readex Pro";
  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}

.mainTab1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow: auto;
}
.mainTab1::-webkit-scrollbar {
  display: none;
}
.outerScrollTab {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #ffffff11;
  border-radius: 50px;
  padding: 3px 20px;
}
.tabActiveButtons1 {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 11px 20px;
  color: #e49f46 !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  font-family: "Readex Pro";
  @media (max-width: 768px) {
    padding: 8px 15px;
  }
}
.tabButtons1 {
  border-radius: 50px;
  padding: 11px 20px;
  white-space: pre;
  cursor: pointer;
  color: rgba(184, 184, 184, 1);
  font-family: "Readex Pro";
  @media (max-width: 768px) {
    padding: 8px 15px;
  }
}

.MuiPaginationItem-outlined {
  border: 1px solid #181719 !important;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: #d2883f !important;
  border: 1px solid #f5bd64 !important;
  color: #fff !important;
}

.tabDisplayBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.textTabBox {
  padding: 5px 15px;
  cursor: pointer;
  color: #ffca64;
  border-bottom: 2px solid #ffca64;
}
.textTabActiveBox {
  padding: 5px 15px;
  color: rgb(184, 184, 184);
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.dialogStyle {
  .MuiDialog-paperFullWidth {
    background: rgba(10, 8, 7, 1);
    box-shadow: 0 1px 0 0 #ffca64, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #ca772a,
      -1px 0 0 0 #ca772a, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #ca772a,
      1px 1px 0 0 #f4a91b, -1px -1px 0 0 #ca772a;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.backButton {
  background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  padding: 8px !important;
  margin-right: 8px !important;
}
.tooltipBox {
  font-size: 200px;
}
.displayAlign {
  display: flex;
  align-items: center;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 1%);
}
.leaderboardButton {
  background: var(--linear, linear-gradient(164deg, #ffca64 0%, #ca772a 100%));
  border-radius: 0px 0px 30px 30px;
  min-width: 105px;
  width: 245px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lederboardCenter {
  display: flex;
  justify-content: center;
  margin-top: -42px;
}
.closeButton {
  position: absolute !important;
  right: 11px !important;
  top: 2px !important;
}
.tickenumber {
  color: #fff;
  border: 1px solid #fff;
  padding: 17px 12px;
  background: rgba(255, 255, 255, 0.04);
  @media (max-width: 600px) {
    padding: 13px 4px;
  }
}
.zoomed {
  transform: scale(1.3); /* Increase size by 20% */
}

.myCustomClass1::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.myCustomClass1:nth-child(odd)::after {
  background: linear-gradient(
    90deg,
    #135a38 0%,
    rgba(13, 135, 204, 0.97) 43.68%,
    rgba(0, 0, 0, 0) 114.45%
  );
}
.myCustomClass::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.myCustomClass:nth-child(odd)::after {
  background: linear-gradient(
    90deg,
    #81155c 0%,
    rgba(57, 70, 187, 0.91) 42.53%,
    rgba(0, 0, 0, 0) 107.41%
  );
}

// .mainStepBox {
//   border: 7px solid #eab671;
//   padding: 30px 44px;
//   position: relative;
//   background: #000;
//   border-radius: 20px 0px 0px 20px;
//   ::after {
//     top: 14%;
//     right: -7px;
//     height: 59%;
//     content: url(images/afterboreder.png);
//     z-index: 9;
//     position: absolute;
//     background: black;
//   }
// }

.trade-full {
  height: 300px;
  @media (max-width: 600px) {
    height: 250px;
  }
}

.mainClx {
  color: rgba(255, 255, 255, 0.6);
}
.mainClx span {
  color: rgba(255, 255, 255, 0.6) !important;
}

.contactusText {
  cursor: pointer;
  font-size: 16px !important;
  text-decoration: underline;
  color: orange;
}

.socialButton {
  padding: 10px !important;
}

.royalText1 {
  position: absolute;
  right: -1px;
  transform: rotate(28deg);
  top: 16px;
  font-weight: bold;
  font-size: 12px;
  background: #ca772a;
  padding: 4px 0x;
  padding: 6px;
  /* border-radius: 10px; */
  /* margin: 10px; */
  z-index: 9;
}

.marquebackground {
  padding: 40px 0;
}

.marqueTextdescription {
  margin: 6px !important;
  font-size: 17px !important;
  background: #111111;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #edae502e;
}
