@media (max-width: 1440px) {
}
@media (max-width: 1462px) {
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .selectGridBox {
    width: 19%;
  }
  .buttonCustom {
    // width: 87px !important;
    // height: 87px !important;
  }
}
@media (max-width: 1280px) {
  .influcenbox .textBox {
    min-height: 114px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 250px;
  }
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .selectGridBox {
    width: 20%;
  }
  .buttonCustom {
    // width: 87px !important;
    // height: 87px !important;
  }
}
@media (max-width: 1110px) {
  .selectGridBox {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
  .selectGridBox {
    width: 30%;
    margin: 5px;
  }
}
@media (max-width: 1440px) {
  .albumslickbottomslider .slick-next {
    left: 51% !important;
  }
}

@media (max-width: 1280px) {
  .albumslickbottomslider .slick-next {
    left: 52% !important;
  }
}
@media (max-width: 991px) {
  .socialresponBox {
    justify-content: space-around;
  }
  .logoDrawerBox {
    // padding-top: 12px;
    /* top: 20px; */
    width: auto;
    cursor: pointer;
    max-width: 75px;
  }
  .sideMenuBox {
    height: calc(100vh - 355px);
    overflow: auto;
  }
  .bannerimg1 {
    width: auto;
    max-width: 200px;
  }
  .albumslickbottomslider .slick-next {
    left: 55% !important;
  }
  .selectGridBox {
    width: 18%;
    margin: 5px;
  }
  .slick-next {
    left: 52% !important;
  }
  .circleBox {
    width: 500px;
    height: 500px;
  }
  .circleBoxNew {
    width: 500px;
    height: 500px;
  }

  .squarecontentBox {
    width: 40px !important;
  }
  .containerBox {
    padding: 25px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  // .orangeshadeBox {
  //   display: none;
  // }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}

@media (max-width: 767px) {
  .tickenumber {
    padding: 3px !important;
  }
  .slicktopslider .slick-track {
    padding: 0px 0 0px;
  }
  .firstNumber {
    width: 42px !important;
  }
  .footballslider .slick-next {
    right: -11px !important;
  }
  .footballslider .slick-prev {
    left: -11px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 100px;
  }
  .numberText {
    left: -35px !important;
    top: 79px;
    width: 52px;
  }
  .albumslickbottomslider .slick-prev {
    left: 45% !important;
  }
  .buttonCustom {
    // width: 50px !important;
    // height: 50px !important;
    padding: 12px 0px;
  }
  .selectGridBox {
    width: 28%;
    margin: 5px;
  }

  .slick-next {
    left: 52% !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }

  .dollerImg2 {
    left: 0;
    width: 117px !important;
    top: 41%;
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 62px;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
  .imgNoData img {
    width: 100%;
  }
}
