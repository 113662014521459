@charset "UTF-8";
body {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  background-color: #0d0d0d;
  color: #ffffff;
  font-family: "K2D", sans-serif;
}

.dFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.royalrightImg {
  width: auto !important;
  max-width: 97px;
  margin-top: 34px;
  margin-right: -3px;
}

.numberText1 {
  left: -70px !important;
}

.numberText {
  position: absolute;
  left: -46px;
  font-size: 101px !important;
  color: #ca772a;
  top: 54px;
  z-index: 9;
}

.layoubackgroundImg {
  position: fixed;
  width: auto;
  max-width: 100%;
  opacity: 0.5;
}

.sideMenuBox {
  height: calc(100vh - 450px);
  overflow: auto;
}

.sideMenuBox::-webkit-scrollbar {
  display: none;
}

.logoDrawerBox {
  padding-top: 22px;
  /* top: 20px; */
  width: auto;
  cursor: pointer;
  max-width: 119px;
}

.paperBox {
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 15px;
  padding: 20px;
}

.slickbottomslider {
  width: 100%;
  position: relative;
}
.slickbottomslider .slick-initialized .slick-slide.slick-current .active {
  transform: scale(1.15);
  transition: all 0.4s ease-in;
}
.slickbottomslider .slick-next:before,
.slickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px !important;
}
.slickbottomslider .slick-track {
  padding: 60px 0;
}
.slickbottomslider .slick-prev:before {
  content: "‹";
}
.slickbottomslider .slick-next:before {
  content: "›";
}
.slickbottomslider .slick-list {
  padding-left: 0px !important;
}
.slickbottomslider .slick-prev {
  left: auto !important;
  top: -14px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: #1c1c1b !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .slickbottomslider .slick-prev {
    top: 21px !important;
  }
}
.slickbottomslider .slick-next {
  top: -14px !important;
  left: auto !important;
  right: 5px !important;
  color: #fff !important;
  background-color: #161616 !important;
  z-index: 999 !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .slickbottomslider .slick-next {
    top: 21px !important;
  }
}
.slickbottomslider .slick-next:before,
.slickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: #f5c843;
  margin-top: -17px;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 18px !important;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgb(255, 212, 129) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid rgb(255, 212, 129);
  border-radius: 50px;
  width: 21px !important;
  height: 21px !important;
  line-height: 23px !important;
}

.slick-dots {
  bottom: -27px !important;
  z-index: 999;
}
@media (max-width: 1556px) {
  .slick-dots {
    bottom: -38px !important;
  }
}

.slickbottomslider .slick-track {
  padding: 40px 0 23px;
}

.yellowShade {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 30%;
  top: 50%;
  transform: translateX(-50%);
  background: radial-gradient(29.3% 29.3% at 55.72% 49.12%, #ffca64 0%, rgba(245, 200, 67, 0) 100%);
  opacity: 0.45;
  filter: blur(190px);
  max-width: 100%;
}

.tableShadow {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 50%;
  top: -13%;
  transform: translateX(-50%);
  background: radial-gradient(29.3% 29.3% at 55.72% 49.12%, #ffca64 0%, rgba(245, 200, 67, 0) 100%);
  opacity: 0.45;
  filter: blur(301px);
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(92.79deg, #ffb000 -32.2%, #ffb000 5.51%, #ca772a 54.01%, #ca772a 110.93%);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(92.79deg, #ffb000 -32.2%, #ffb000 5.51%, #ca772a 54.01%, #ca772a 110.93%);
}

::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

::-webkit-scrollbar-corner {
  background: #f2f2f2;
}

body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.royalText {
  font-size: 35px !important;
  font-weight: 600;
  font-family: "K2D", sans-serif !important;
  line-height: 45px !important;
  text-align: left;
  text-transform: uppercase;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectGridBox {
  width: 20%;
}

.logoImg {
  width: 120px;
}

.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.bgx1 {
  animation: rotating 26s linear infinite;
}

.bgx2 {
  animation: rotating2 6s linear infinite;
}

.crypto {
  animation: rotating3 3s linear infinite;
}
.goldshadow {
  width: 800px;
  top: 25%;
  opacity: 0.3;
  filter: blur(130px);
  height: 400px;
  border-radius: 1000px;
  left: 23%;
  z-index: -1;
  position: absolute;
  background-image: radial-gradient(22.67% 9.68% at 67.26% 8.27%, #ffca64 86%, #ffca64 41%);
}
@media (max-width: 991px) {
  .goldshadow {
    width: 216px;
    height: 216px;
  }
}
@media (max-width: 401px) {
  .goldshadow {
    width: 106px;
    height: 106px;
  }
}

@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
}
.sliderBox img {
  width: auto;
  max-width: 165px;
}

.shadeBox {
  right: auto;
  left: 0;
  width: 450px;
  /* bottom: auto; */
  filter: blur(230px);
  height: 550px;
  opacity: 0.55;
  top: 0;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #ff6600 0%, rgba(255, 102, 0, 0.3098039216) 95.78%);
}

.orangeshadeBox {
  right: 0;
  left: 34%;
  width: 400px;
  /* bottom: auto; */
  filter: blur(147px);
  height: 400px;
  opacity: 0.55;
  top: 42%;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgba(255, 202, 100, 0.6705882353) 0%, rgba(226, 150, 62, 0.81) 95.78%);
}

.TopbarButton {
  padding: 8px 24px;
  margin-right: 10px !important;
}

.centerImg {
  position: absolute !important;
  left: 0 !important;
  /* background: gray; */
  width: 38px;
  margin-right: 41px;
}

.centerbannerimg {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  z-index: 1;
  opacity: 0.5;
  min-width: 800px;
  max-width: 60%;
}

.orangeshadeBoxWon {
  right: 0;
  left: 34%;
  width: 500px;
  /* bottom: auto; */
  filter: blur(227px);
  height: 500px;
  opacity: 0.55;
  top: 21%;
  z-index: 1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgba(255, 202, 100, 0.57) 0%, rgba(226, 150, 62, 0.68) 95.78%);
}

.dollerImg1 {
  position: absolute;
  right: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg2 {
  position: absolute;
  left: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg3 {
  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 119px !important;
  bottom: -29px;
}

.ticketbannerImg {
  position: absolute;
  top: -122px;
  z-index: -1;
  width: auto !important;
  max-width: 100% !important;
}

.ticketAnimationBox {
  animation: 3s ease-in-out 0s infinite normal none running rotating4;
}

@keyframes rotating4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.animationText {
  color: #fff;
  animation: 2s anim-lineUp ease-out infinite;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0.5;
    transform: translateY(80%);
  }
  20% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.3;
    transform: translateY(0%);
  }
  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.hedaerTopbar {
  padding: 20px 0;
}

.containerBox {
  padding: 0px 80px !important;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #5e4a2e;
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
}

.tabActiveButtons {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  border-radius: 50px;
  padding: 11px 35px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabActiveButtons {
    padding: 11px 23px;
  }
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgb(184, 184, 184);
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabButtons {
    padding: 11px 23px;
  }
}

.mainTab1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

.mainTab1::-webkit-scrollbar {
  display: none;
}

.outerScrollTab {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.0666666667);
  border-radius: 50px;
  padding: 3px 20px;
}

.tabActiveButtons1 {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 11px 20px;
  color: #e49f46 !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabActiveButtons1 {
    padding: 8px 15px;
  }
}

.tabButtons1 {
  border-radius: 50px;
  padding: 11px 20px;
  white-space: pre;
  cursor: pointer;
  color: rgb(184, 184, 184);
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabButtons1 {
    padding: 8px 15px;
  }
}

.MuiPaginationItem-outlined {
  border: 1px solid #181719 !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #d2883f !important;
  border: 1px solid #f5bd64 !important;
  color: #fff !important;
}

.tabDisplayBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.textTabBox {
  padding: 5px 15px;
  cursor: pointer;
  color: #ffca64;
  border-bottom: 2px solid #ffca64;
}

.textTabActiveBox {
  padding: 5px 15px;
  color: rgb(184, 184, 184);
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.dialogStyle .MuiDialog-paperFullWidth {
  background: rgb(10, 8, 7);
  box-shadow: 0 1px 0 0 #ffca64, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #ca772a, -1px 0 0 0 #ca772a, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #ca772a, 1px 1px 0 0 #f4a91b, -1px -1px 0 0 #ca772a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.backButton {
  background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  padding: 8px !important;
  margin-right: 8px !important;
}

.tooltipBox {
  font-size: 200px;
}

.displayAlign {
  display: flex;
  align-items: center;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
}

.leaderboardButton {
  background: var(--linear, linear-gradient(164deg, #ffca64 0%, #ca772a 100%));
  border-radius: 0px 0px 30px 30px;
  min-width: 105px;
  width: 245px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lederboardCenter {
  display: flex;
  justify-content: center;
  margin-top: -42px;
}

.closeButton {
  position: absolute !important;
  right: 11px !important;
  top: 2px !important;
}

.tickenumber {
  color: #fff;
  border: 1px solid #fff;
  padding: 17px 12px;
  background: rgba(255, 255, 255, 0.04);
}
@media (max-width: 600px) {
  .tickenumber {
    padding: 13px 4px;
  }
}

.zoomed {
  transform: scale(1.3); /* Increase size by 20% */
}

.myCustomClass1::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.myCustomClass1:nth-child(odd)::after {
  background: linear-gradient(90deg, #135a38 0%, rgba(13, 135, 204, 0.97) 43.68%, rgba(0, 0, 0, 0) 114.45%);
}

.myCustomClass::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.myCustomClass:nth-child(odd)::after {
  background: linear-gradient(90deg, #81155c 0%, rgba(57, 70, 187, 0.91) 42.53%, rgba(0, 0, 0, 0) 107.41%);
}

.trade-full {
  height: 300px;
}
@media (max-width: 600px) {
  .trade-full {
    height: 250px;
  }
}

.mainClx {
  color: rgba(255, 255, 255, 0.6);
}

.mainClx span {
  color: rgba(255, 255, 255, 0.6) !important;
}

.contactusText {
  cursor: pointer;
  font-size: 16px !important;
  text-decoration: underline;
  color: orange;
}

.socialButton {
  padding: 10px !important;
}

.royalText1 {
  position: absolute;
  right: -1px;
  transform: rotate(28deg);
  top: 16px;
  font-weight: bold;
  font-size: 12px;
  background: #ca772a;
  padding: 4px 0x;
  padding: 6px;
  /* border-radius: 10px; */
  /* margin: 10px; */
  z-index: 9;
}

.marquebackground {
  padding: 40px 0;
}

.marqueTextdescription {
  margin: 6px !important;
  font-size: 17px !important;
  background: #111111;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(237, 174, 80, 0.1803921569);
}

@media (max-width: 1462px) {
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .selectGridBox {
    width: 19%;
  }
}
@media (max-width: 1280px) {
  .influcenbox .textBox {
    min-height: 114px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 250px;
  }
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .selectGridBox {
    width: 20%;
  }
}
@media (max-width: 1110px) {
  .selectGridBox {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
  .selectGridBox {
    width: 30%;
    margin: 5px;
  }
}
@media (max-width: 1440px) {
  .albumslickbottomslider .slick-next {
    left: 51% !important;
  }
}
@media (max-width: 1280px) {
  .albumslickbottomslider .slick-next {
    left: 52% !important;
  }
}
@media (max-width: 991px) {
  .socialresponBox {
    justify-content: space-around;
  }
  .logoDrawerBox {
    /* top: 20px; */
    width: auto;
    cursor: pointer;
    max-width: 75px;
  }
  .sideMenuBox {
    height: calc(100vh - 355px);
    overflow: auto;
  }
  .bannerimg1 {
    width: auto;
    max-width: 200px;
  }
  .albumslickbottomslider .slick-next {
    left: 55% !important;
  }
  .selectGridBox {
    width: 18%;
    margin: 5px;
  }
  .slick-next {
    left: 52% !important;
  }
  .circleBox {
    width: 500px;
    height: 500px;
  }
  .circleBoxNew {
    width: 500px;
    height: 500px;
  }
  .squarecontentBox {
    width: 40px !important;
  }
  .containerBox {
    padding: 25px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}
@media (max-width: 767px) {
  .tickenumber {
    padding: 3px !important;
  }
  .slicktopslider .slick-track {
    padding: 0px 0 0px;
  }
  .firstNumber {
    width: 42px !important;
  }
  .footballslider .slick-next {
    right: -11px !important;
  }
  .footballslider .slick-prev {
    left: -11px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 100px;
  }
  .numberText {
    left: -35px !important;
    top: 79px;
    width: 52px;
  }
  .albumslickbottomslider .slick-prev {
    left: 45% !important;
  }
  .buttonCustom {
    padding: 12px 0px;
  }
  .selectGridBox {
    width: 28%;
    margin: 5px;
  }
  .slick-next {
    left: 52% !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }
  .dollerImg2 {
    left: 0;
    width: 117px !important;
    top: 41%;
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 62px;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
  .imgNoData img {
    width: 100%;
  }
}
.bannerBoxShadow {
  position: relative;
}

.bannerBoxShadow::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: linear-gradient(135deg, #ae7200 0%, #d56a06 100%);
  filter: blur(150px);
  z-index: -1;
  opacity: 0.34;
  transform: translate(-50%, -50%);
}

.slicktopslider .slick-prev {
  left: 0 !important;
  top: -14px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}

.slicktopslider .slick-next {
  left: auto !important;
  top: -14px !important;
  right: 10px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}

.slicktopslider .slick-next:before,
.slicktopslider .slick-prev:before {
  font-size: 67px;
  line-height: 1;
  opacity: 0.75;
  color: #ffffff;
  margin-top: -17px;
}

.footballslider .slick-next,
.footballslider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  border-radius: 50%;
  background: 0 0;
}
.footballslider .slick-prev:before {
  content: "‹";
}
.footballslider .slick-next:before {
  content: "›";
}
.footballslider .slick-prev {
  left: -41px;
  top: -20px !important;
  top: -163px !important;
  right: 60px !important;
  top: 50% !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
}
.footballslider .slick-prev:hover {
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
}
.footballslider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.footballslider .slick-next {
  top: 50% !important;
  bottom: -20px !important;
  left: auto !important;
  right: -41px;
  color: #fff !important;
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
  z-index: 999 !important;
}
.footballslider .slick-next:hover {
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
}
.footballslider .slick-next.slick-disabled {
  opacity: 0.5;
}
.footballslider .slick-next:before,
.footballslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px !important;
}/*# sourceMappingURL=main.css.map */