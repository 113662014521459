.bannerBoxShadow {
  position: relative;
}
.bannerBoxShadow::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: linear-gradient(135deg, #ae7200 0%, #d56a06 100%);
  filter: blur(150px);
  z-index: -1;
  opacity: 0.34;
  transform: translate(-50%, -50%);
}

.slicktopslider .slick-prev {
  left: 0 !important;
  top: -14px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}
.slicktopslider .slick-next {
  left: auto !important;
  top: -14px !important;
  right: 10px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}

.slicktopslider .slick-next:before,
.slicktopslider .slick-prev:before {
  font-size: 67px;
  line-height: 1;
  opacity: 0.75;
  color: #ffffff;
  margin-top: -17px;
}

.footballslider {
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    border-radius: 50%;
    background: 0 0;
  }
  .slick-prev:before {
    content: "‹";
  }
  .slick-next:before {
    content: "›";
  }
  .slick-prev {
    left: -41px;
    top: -20px !important;
    top: -163px !important;
    right: 60px !important;
    top: 50% !important;
    color: #fff !important;
    z-index: 999 !important;
    background-color: #edae50 !important;
    border: 2px solid #ffca64;

    &:hover {
      background-color: #edae50 !important;
      border: 2px solid #ffca64;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next {
    top: 50% !important;
    bottom: -20px !important;
    left: auto !important;
    right: -41px;
    color: #fff !important;
    background-color: #edae50 !important;
    border: 2px solid #ffca64;
    z-index: 999 !important;
    &:hover {
      background-color: #edae50 !important;
      border: 2px solid #ffca64;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }
}
